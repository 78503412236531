import React, {ChangeEvent, ChangeEventHandler, FC, useState} from 'react';
import styles from './FormQuestion.module.css';
import Input from "../Input/Input";
import cn from "classnames";

interface FormQuestionProps {
    question: string;
    type: 'text' | 'email' | 'number';
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    warning?: string;
    isError: '' | 'valid' | 'invalid';
    autocomplete?: string;
}

const FormQuestion: FC<FormQuestionProps> =
    ({
         question,
         type,
         value, onChange,
         warning,
         isError,
         autocomplete,
     }) => {

    return (
        <div className={cn('container_form', styles.question__container)}>
            <p className={styles.question}>{question}</p>
            <div className={styles.question__form}>
                <Input type={type} onChange={onChange} value={value} isValid={isError} autocomplete={autocomplete}/>
            </div>
            { warning &&
                <p className={cn(styles.warning, isError === 'invalid' && styles.error)}>{warning}</p>
            }
        </div>
    );
};

export default FormQuestion;
