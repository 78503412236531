import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import RadioQuestion from "../library/RadioQuestion/RadioQuestion";
import {validateRatio} from "../../validation/validation";

interface WorkWithProps {
    workWith: string;
    setWorkWith: Function;
    setIsWorkWithValid: Function;
    isBtnClicked: boolean;
}

const WorkWith: FC<WorkWithProps> = ({workWith, setIsWorkWithValid, setWorkWith, isBtnClicked}) => {
    const [workWithWarning, setWorkWithWarning] = useState<string>('');
    const [workWithError, setWorkWithError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        workWith && checkWorkWith() || isBtnClicked && checkWorkWith();
    }, [workWith, isBtnClicked]);

    const handleChangeWorkWith = (event: ChangeEvent<HTMLInputElement>) => {
        setWorkWith(event.target.value);
    };

    const checkWorkWith = () => {
        const isValid = validateRatio(workWith);
        setIsWorkWithValid(isValid);
        if (isValid) {
            setWorkWithError('valid');
            setWorkWithWarning('');
        } else {
            setWorkWithError('invalid');
            setWorkWithWarning('This field should be filled in');
        }
    };

    return (
        <RadioQuestion
            question={"Who would you be comfortable to work with?"}
            radioButtonText={['A woman', 'A man', 'Indifferent']}
            onChange={handleChangeWorkWith}
            warning={workWithWarning}
            isError={workWithError}
            radioFormType={'workWith'}
            elementsValues={['woman', 'man', 'both']}
            currentValue={workWith}
        />
    );
};

export default WorkWith;
