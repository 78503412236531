import React, {ChangeEventHandler, FC} from 'react';
import styles from './RadioButton.module.css';

interface RadioButtonProps {
    onChange: ChangeEventHandler<HTMLInputElement>;
    elementsText: Array<string>;
    elementsValues?: Array<string>;
    formType: 'experience' | 'format' | 'workWith' | 'method';
    currentValue?: string;
}

const RadioButton: FC<RadioButtonProps> =
    ({
         onChange, elementsText, formType,
         elementsValues= elementsText, currentValue = ''
    }) => {
    return (
        <div className={styles.group}>
            {elementsText.map((elemText, index) => (
                <div className={styles.group__item} key={index}>
                    <input
                        id={`${formType}-${index}`}
                        type="radio"
                        name={formType}
                        value={elementsValues[index]}
                        onChange={onChange}
                        checked={currentValue === elementsValues[index]}
                    />
                    <label htmlFor={`${formType}-${index}`}>{elemText}</label>
                </div>
            ))}
        </div>
    );
};

export default RadioButton;
