import React, {FC} from 'react';
import styles from './ButtonStep.module.css';
import cn from "classnames";

interface ButtonStepProps {
    text: string;
    current: boolean;
    currentActiveButton: number;
    id: number;
}

const ButtonStep: FC<ButtonStepProps> = ({text, current, currentActiveButton, id}) => {
    return (
        <button className={cn(styles.step, id > currentActiveButton && styles.inactive)}>
            <p className={cn(styles.step__text, current && styles.current)}>{text}</p>
            <div className={cn(styles.step__line)}></div>
        </button>
    );
};

export default ButtonStep;
