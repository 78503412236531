import axios from "axios";
import {IParentSymptom} from "../layout/questionnaire/PersonalQuestions/PersonalQuestions";
import {ILocation} from "../components/library/RadioGroup/RadioGroup";

export default class PersonalQuestionsService {
    static async getSymptoms () {
        const token = localStorage.getItem('token');
        const response = await axios.get<IParentSymptom[]>(`${process.env.REACT_APP_HOST}/api/v1/symptoms/`, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return response.data;
    }

    static async getLocations () {
        const token = localStorage.getItem('token');
        const response = await axios.get<ILocation[]>(`${process.env.REACT_APP_HOST}/api/v1/locations/`, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return response.data
        // {
        //     'locations': [
        //         {"id": 1, "name": 'Johannesburg, Gauteng'},
        //         {"id": 2, "name": 'Makhado, Limpopo'},
        //         {"id": 3, "name": 'Cape Town, Western Cape'}
        //     ]
        // }
    }

    static async sendData (isPersonal: boolean, symptoms: Array<number>, workWith: string, method: string, location: number | null): Promise<boolean> {
        const token = localStorage.getItem('token');
        return await axios.put(`${process.env.REACT_APP_HOST}/api/v1/profile/`, {
            is_personal: isPersonal,
            symptoms: symptoms,
            desired_gender: workWith,
            method: method,
            location: location,
        }, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
            .then(function () {
                return true;
            })
            .catch(function (error) {
                console.log(error.message);
                return false;
            })
    }
}
