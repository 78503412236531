import React from 'react';
import Navbar from "../components/library/Navbar/Navbar";
import Footer from "../components/library/Footer/Footer";
import FeedbackMessage from "../layout/general/feedback/FeedbackMessage";

const Feedback = () => {
    return (
        <div>
            <Navbar/>
            <FeedbackMessage/>
            <Footer/>
        </div>
    );
};

export default Feedback;
