import React, {FC} from 'react';
import styles from "./SpecialistFeatures.module.css";
import SparkIcon from "../../../static/images/spark.svg";
import CoinIcon from "../../../static/images/coin.svg";
import LocationIcon from "../../../static/images/location-grey.svg";

interface SpecialistFeaturesProps {
    experience: number;
    costOnline: number;
    costOffline: number;
    location: string
}

const SpecialistFeatures: FC<SpecialistFeaturesProps> =
    ({
         experience, costOnline, costOffline, location
    }) => {
    return (
        <div className={styles.features}>
            <div className={styles.feature}>
                <input className={styles.feature__icon} type={"image"} src={SparkIcon}/>
                <p className={styles.feature__text}>
                    {experience} {experience === 1 ? 'year' : 'years'} experience
                </p>
            </div>
            <div className={styles.feature}>
                <input className={styles.feature__icon} type={"image"} src={CoinIcon}/>
                <div>
                    {costOnline && <p className={styles.feature__text}>R{costOnline} / online</p>}
                    {costOffline && <p className={styles.feature__text}>R{costOffline} / offline</p>}
                </div>
            </div>
            <div className={styles.feature}>
                <input className={styles.feature__icon} type={"image"} src={LocationIcon}/>
                <p className={styles.feature__text}>{location}</p>
            </div>
        </div>
    );
};

export default SpecialistFeatures;
