import React, {FC} from 'react';
import styles from "./SpecialistSymptoms.module.css";
import {IKidSymptom} from "../../../layout/questionnaire/PersonalQuestions/PersonalQuestions";

interface SpecialistSymptomsProps {
    symptoms?: IKidSymptom[];
    amountShown: number
}

const SpecialistSymptoms: FC<SpecialistSymptomsProps> = ({symptoms= [], amountShown}) => {
    const symptomsShown: string[] = [];
    amountShown = Math.min(symptoms?.length, amountShown);
    if (symptoms?.length > 0) {
        for (let i = 0; i < amountShown; i++) {
            symptomsShown.push(symptoms[i]?.symptom);
        }
        let amountNotShown = symptoms?.length - amountShown;
        if (amountNotShown > 0) {
            symptomsShown.push(`+ ${amountNotShown} more`);
        }
    }


    return (
        <div className={styles.symptoms}>
            {symptomsShown.map((symptom, index) => (
                <div className={styles.symptoms__elem} key={index}>
                    <p className={styles.symptoms__elem_text}>{symptom}</p>
                </div>
            ))}
        </div>
    );
};

export default SpecialistSymptoms;
