import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import cn from "classnames";
import styles from './RadioGroup.module.css';
import PersonalQuestionsService from "../../../API/PersonalQuestionsService";
import Loader from "../Loader/Loader";

interface RadioGroupProps {
    method: string;
    location: number;
    setLocation: Function;
}

export interface ILocation {
    id: number;
    name: string;
}

const RadioGroup: FC<RadioGroupProps> = ({method, location, setLocation}) => {
    const [locations, setLocations] = useState<Array<ILocation>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (locations.length > 0) {
            if (method !== 'online' && method !== '') setLocation(locations[0].id);
        }
    }, [method]);

    useEffect(() => {
        fetchLocations();
    }, []);

    async function fetchLocations () {
        setIsLoading(true);
        try {
            const locationsArr = await PersonalQuestionsService.getLocations();
            setLocations(locationsArr);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeLocation = (event: ChangeEvent<HTMLInputElement>) => {
        const currentLocation = locations.find(({name}) => name === event.target.value);
        setLocation(currentLocation?.id);
    }

    return (
        <div className={cn(styles.radio_group, method === 'online' || method === '' ? styles.radio_group__invisible : styles.radio_group__visible)}>
            {isLoading
                ? <Loader/>
                : locations.map((el, index) => (
                        <div className={styles.radio_group__item} key={index}>
                            <input
                                type={'radio'} id={`location-${el.id}`} name={'location'}
                                value={el.name} onChange={handleChangeLocation} checked={el.id === location}
                            />
                            <label htmlFor={`location-${el.id}`}>{el.name}</label>
                        </div>
                    ))
            }
        </div>
    );
};

export default RadioGroup;
