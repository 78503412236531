import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import FormQuestion from "../library/FormQuestion/FormQuestion";
import {MAX_AGE, MIN_AGE} from "../../layout/registration/generalQuestions/generalQuestions";
import {validateNumber} from "../../validation/validation";

interface AgeProps {
    age: string;
    setAge: Function;
    isBtnClicked: boolean;
    setIsAgeValid: Function;
}

const Age: FC<AgeProps> = ({age, setAge, isBtnClicked, setIsAgeValid}) => {
    const [ageWarning, setAgeWarning] = useState<string>('You must be 18 or older to receive services');
    const [ageError, setAgeError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        (age && checkAge()) || (isBtnClicked && checkAge());
    }, [age, isBtnClicked]);

    const handleChangeAge = (event: ChangeEvent<HTMLInputElement>) => {
        setAge(event.target.value);
    };

    const checkAge = () => {
        setIsAgeValid(validateNumber(Number(age), MIN_AGE, MAX_AGE));
        if (Number(age) < MIN_AGE) {
            setAgeError('invalid');
            setAgeWarning('You must be 18 or older to receive services');
        } else if (Number(age) > MAX_AGE){
            setAgeError('invalid');
            setAgeWarning('Fill in the age correctly');
        } else {
            setAgeError('valid');
            setAgeWarning('You must be 18 or older to receive services');
        }
    }

    return (
        <FormQuestion
            question={"How old are you?"}
            type={'number'}
            value={age}
            onChange={handleChangeAge}
            warning={ageWarning}
            isError={ageError}
        />
    );
};

export default Age;
