import React from 'react';
import styles from './FeedbackMessage.module.css';
import TickIcon from '../../../static/images/tick.svg';
import Button from "../../../components/library/Button/Button";
import cn from "classnames";
import {Link} from "react-router-dom";

const FeedbackMessage = () => {
    return (
        <div className={cn('container', styles.message__container)}>
            <input className={styles.icon} type={'image'} src={TickIcon} alt={'tick'}/>
            <p className={cn(styles.text, 'text__light')}>
                Within an hour, we will set up a WhatsApp chat for you with the specialist of your choice.
                If this does not happen, please contact us by email at
                <span className={styles.email}> balanceonline.co.za@gmail.com</span>
            </p>
            <Link to={'/'}>
                <Button appearance={'blue'}>To main page</Button>
            </Link>
        </div>
    );
};

export default FeedbackMessage;
