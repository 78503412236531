import React from 'react';
import styles from './FAQ.module.css';
import Question from "../../../components/mainPage/Question/Question";

const Faq = () => {
    const FAQ = [
        {
            question: "How do you select psychologists?",
            answer: "The questionnaire helps to clarify your needs, and the algorithm analyzes the database of " +
                "psychologists and selects those who are most effective at working with the request.",
        },
        {
            question: "Which therapy is better, online or offline? ",
            answer: "Both options are effective. Your contact with the psychologist, the method of work, and " +
                "motivation have a greater impact on the outcome of therapy than the format.",
        },
        {
            question: "Therapy might be too expensive. What should I do? ",
            answer: "With Balance, the cost of a session starts at R400. Therapy isn’t a year long subscription, " +
                "it will be up to you and your therapist to decide how many sessions are necessary for you.",
        },
        {
            question: "I'm afraid that the psychologist will harm me. How will you help me?",
            answer: "Balance takes all your concerns and issues seriously and you can contact our customer " +
                "support and request to change your therapist. Balance prides itself on our strict and professional " +
                "vetting of the psychologists on this platform and we make sure that therapy is truly safe " +
                "and secure for everyone.",
        },
    ];

    return (
        <div className="container">
            <h2>Questions that you may have</h2>
            <div className={styles.questions}>
                <hr/>
                {FAQ.map((elem, index) => (
                    <Question question={elem.question} answer={elem.answer} key={index} id={index}/>
                ))}
            </div>
        </div>
    );
};

export default Faq;
