import React, {ChangeEventHandler, FC} from 'react';
import cn from "classnames";
import styles from "../FormQuestion/FormQuestion.module.css";
import RadioButton from "../RadioButton/RadioButton";

interface RadioQuestionProps {
    question: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    warning?: string;
    isError: '' | 'valid' | 'invalid';
    radioButtonText: Array<string>;
    elementsValues?: Array<string>;
    radioFormType: 'experience' | 'format' | 'workWith' | 'method';
    currentValue?: string;
}

const RadioQuestion: FC<RadioQuestionProps> =
    ({
         question, onChange, warning, isError,
         radioButtonText, radioFormType, elementsValues, currentValue=''
     }) => {

    return (
        <div className={cn('container_form', styles.question__container, radioButtonText?.length > 2 && styles.big__question)}>
            <p className={styles.question}>{question}</p>
            <div className={styles.question__form}>
                <RadioButton
                    onChange={onChange} elementsText={radioButtonText}
                    formType={radioFormType} elementsValues={elementsValues} currentValue={currentValue}
                />
            </div>
            { warning &&
                <p className={cn(styles.warning, isError === 'invalid' && styles.error)}>{warning}</p>
            }
        </div>
    );
};

export default RadioQuestion;
