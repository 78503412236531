import React, {FC} from 'react';
import styles from './CardFeatures.module.css';

interface CardFeaturesProps {
    experience?: number;
    location?: string;
    costOnline?: number;
    costOffline?: number;
}

const CardFeatures: FC<CardFeaturesProps> =
    ({
         experience, location,
         costOffline, costOnline
    }) => {

    return (
        <div className={styles.features}>
            <div className={styles.feature}>
                <p className={styles.feature__header}>Work experience</p>
                <p className={styles.feature__text}>{experience} {experience === 1 ? 'year' : 'years'}</p>
            </div>
            <div className={styles.feature}>
                <p className={styles.feature__header}>Cost of one session</p>
                {costOnline && <p className={styles.feature__text}>
                    <span className={styles.feature__text_bold}>R{costOnline}</span> / online
                </p>}
                {costOffline && <p className={styles.feature__text}>
                    <span className={styles.feature__text_bold}>R{costOffline}</span> / offline
                </p>}
            </div>
            <div className={styles.feature}>
                <p className={styles.feature__header}>Location</p>
                <p className={styles.feature__text}>{location}</p>
            </div>
        </div>
    );
};

export default CardFeatures;
