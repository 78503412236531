import React from 'react';
import Navbar from "../components/library/Navbar/Navbar";
import Footer from "../components/library/Footer/Footer";
import ErrorMessage from "../layout/general/error/ErrorMessage";

const Error = () => {
    return (
        <div>
            <Navbar/>
            <ErrorMessage/>
            <Footer/>
        </div>
    );
};

export default Error;
