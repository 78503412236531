import React, {FC} from 'react';
import styles from './SpecialistAbout.module.css';
import SpecialistSymptoms from "../../../components/specialists/SpecialistSymptoms/SpecialistSymptoms";
import Question from "../../../components/mainPage/Question/Question";
import {IPsychologist} from "../SpecialistsList/SpecialistsList";
import CardFeatures from "../../../components/specialists/CardFeatures/CardFeatures";
import IconInfo from '../../../static/images/icon_info.svg';
import IconEducation from '../../../static/images/icon_education.svg';
import IconExperience from '../../../static/images/icon_experience.svg';

interface SpecialistAboutProps {
    specialist?: IPsychologist;
}

const iconMapping = {
    'education': IconEducation,
    'experience': IconExperience,
    'info': IconInfo,
}

const SpecialistAbout: FC<SpecialistAboutProps> = ({specialist}) => {
    return (
        <div>
            <h3 className={styles.name}>{specialist?.name}</h3>
            <p className={styles.about}>{specialist?.about}</p>
            <CardFeatures
                experience={specialist?.experience}
                location={specialist?.location}
                costOffline={specialist?.offline_cost}
                costOnline={specialist?.online_cost}
            />
            <p className={styles.header}>My expertise</p>
            <SpecialistSymptoms symptoms={specialist?.symptoms} amountShown={6}/>
            <p className={styles.text}>{specialist?.work_with}</p>
            {specialist?.extra_info.length !== 0 && <hr/>}
            {specialist?.extra_info &&
                specialist?.extra_info.map((info, index) => (
                <Question
                    question={info.question}
                    answer={info.body}
                    id={index+10}
                    hasIcon={true}
                    icon={iconMapping[info.kind]}
                    key={index+10}
                />
            ))}
        </div>
    );
};

export default SpecialistAbout;
