import React, {ChangeEventHandler, FC, MouseEventHandler, PropsWithChildren} from 'react';
import styles from './Button.module.css'

interface ButtonProps {
    appearance: 'white' | 'blue_wide' | 'blue_big' | 'green' | 'blue';
    onClick?: MouseEventHandler<HTMLButtonElement>;

}

const Button: FC<PropsWithChildren<ButtonProps>> = ({appearance, children , onClick}) => {
    return (
        <button className={styles[appearance]} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
