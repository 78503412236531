import React, { FC } from 'react';
import styles from "./Logo.module.css";
import {Link} from "react-router-dom";

const Logo: FC = () => {
    return (
        <div className={styles.logo}>
            <Link to={'/'} className={styles.logo__a}>Balance</Link>
        </div>
    );
};

export default Logo;
