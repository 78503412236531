import React, {FC, ReactNode} from 'react';
import styles from "./Problems.module.css";
import CardProblem, {CardProblemProps} from "../../../components/mainPage/CardProblem/CardProblem";

const Problems: FC = () => {
    const cards: Array<CardProblemProps> = [
        {
            "appearance": "white",
            "color": "blue",
            "header": "Relationship",
            "text": "We have a lot of conflicts",
        },
        {
            "appearance": "black",
            "color": "white",
            "header": "Burnout",
            "text": "I have no energy for anything",
        },
        {
            "appearance": "white",
            "color": "blue",
            "header": "Decisions",
            "text": "I procrastinate and get overwhelmed",
        },
        {
            "appearance": "black",
            "color": "white",
            "header": "Anxiety",
            "text": "Anxious thoughts before sleep",
        },
        {
            "appearance": "white",
            "color": "green",
            "header": "Self-esteem",
            "text": "Everyone around is better than me",
        },
        {
            "appearance": "black",
            "color": "white",
            "header": "Depression",
            "text": "Life appears to be meaningless",
        },
    ];

    return (
        <div className="container">
            <h2 className={styles.problems__header}>We will match you with a psychologist tailored to your needs</h2>
            <div className={styles.cards}>
                {cards.map((card, index) => (
                    <CardProblem
                        appearance={card.appearance}
                        color={card.color}
                        header={card.header}
                        text={`"${card.text}"`}
                        id={index}
                        key={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default Problems;
