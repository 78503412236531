import Main from "../../pages/Main";
import Registration from "../../pages/Registration";
import Feedback from "../../pages/Feedback";
import {JSX} from "react";
import Error from "../../pages/Error";
import Questions from "../../pages/Questions";
import Specialists from "../../pages/Specialists";
import Psychologist from "../../pages/Psychologist";

interface IRoute {
    path: string,
    element: JSX.Element;
}

export const routes: Array<IRoute> = [
    {path: '/', element: <Main/>},
    {path: '/registration', element: <Registration/>},
    {path: '/success', element: <Feedback/>},
    {path: '/error', element: <Error/>},
    {path: '/questions', element: <Questions/>},
    {path: '/specialists', element: <Specialists/>},
    {path: '/specialists/:id', element: <Psychologist/>}
]
