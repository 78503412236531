import React, {FC} from 'react';
import styles from './Question.module.css';
import PlusIcon from '../../../static/images/plus.svg';
import cn from "classnames";

interface QuestionProps {
    question: string;
    answer: string;
    id: number;
    hasIcon?: boolean;
    icon?: string;
}

const Question: FC<QuestionProps> = ({question, answer, id, hasIcon = false, icon}) => {
    const handleClick = () => {
        const icon = document.getElementById(`question__icon_${id}`);
        icon && icon.classList.toggle(styles.transform__icon);
        const answer = document.getElementById(`answer_${id}`);
        answer && answer.classList.toggle(styles.open);
    }

    return (
        <div className={styles.question__block}>
            <div className={cn(styles.question, hasIcon && styles.grid)} onClick={handleClick}>
                {hasIcon && <input
                    type={"image"}
                    src={`/${icon}`}
                    className={cn(styles.icon)}
                    alt={"question icon"}
                />}
                <h4 className={styles.question__text}>{question}</h4>
                <input
                    type={"image"}
                    src={`/${PlusIcon}`}
                    className={cn(styles.question__icon)}
                    alt={"show answer"}
                    id={`question__icon_${id}`}
                />
            </div>
            <div className={styles.answer} id={`answer_${id}`}>
                <p className={cn(styles.answer__text, hasIcon && styles.small)}>{answer}</p>
            </div>
            <hr/>
        </div>
    );
};

export default Question;
