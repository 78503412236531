import React, { FC } from 'react';
import styles from './CardAdvantage.module.css';
import heart from "../../../static/images/heart.svg";
import wallet from "../../../static/images/wallet.svg";
import chat from "../../../static/images/chat.svg";
import convenience from "../../../static/images/convenience.svg";

const icons = {heart, wallet, chat, convenience};

type IconName = keyof typeof icons;

export interface CardAdvantageProps {
    icon: IconName;
    header: string;
    text: string;
}

const CardAdvantage: FC<CardAdvantageProps> = ({icon, header, text}) => {
    return (
        <div className={styles.card}>
            <input type="image" className={styles.card__icon} src={icons[icon]} alt={'heart'}/>
            <h3 className={styles.card__header}>{header}</h3>
            <p className={styles.card__text}>{text}</p>
        </div>
    );
};

export default CardAdvantage;
