import React, {FC} from 'react';
import styles from './CardProblem.module.css';
import cn from "classnames";
import white from "../../../static/images/sparkle-white.svg";
import black from "../../../static/images/sparkle-black.svg";

const icons = { white, black };

export interface CardProblemProps {
    appearance: "white" | "black";
    color: "blue" | "green" | "white";
    header: string;
    text: string;
    id?: number;
}

const CardProblem: FC<CardProblemProps> = ({appearance, color, header, text, id}) => {
    return (
        <div className={cn(styles.card, styles[`card__${color}`], styles[`card_${id}`])}>
            <div className={cn(styles.card__header, styles[`card__header_${appearance}`])}>
                <input type="image" className={styles.card__header_icon} src={icons[appearance]} alt={"sparkle"}/>
                <p className={cn(styles.card__header_text, styles[appearance])}>{header}</p>
            </div>
            <h3 className={cn(styles.card__text, styles[appearance])}>{text}</h3>
        </div>
    );
};

export default CardProblem;
