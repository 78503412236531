import {IPsychologist} from "../layout/specialists/SpecialistsList/SpecialistsList";
import axios from "axios";

export default class SpecialistsService {
    static async getAll () {
        const token = localStorage.getItem('token');
        const response = await axios.get<IPsychologist[]>(`${process.env.REACT_APP_HOST}/api/v1/specialists/`, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return response.data;
    }

    static async getById (id: number) {
        const token = localStorage.getItem('token');
        const response = await axios.get<IPsychologist>(`${process.env.REACT_APP_HOST}/api/v1/specialists/${id}`, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return response.data;
    }
};
