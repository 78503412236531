import React, {ChangeEventHandler, FC} from 'react';
import cn from "classnames";
import styles from "../FormQuestion/FormQuestion.module.css";
import RadioGroup from "../RadioGroup/RadioGroup";

interface RadioGroupQuestionProps {
    method: string;
    location: number;
    setLocation: Function;
}

const RadioGroupQuestion: FC<RadioGroupQuestionProps> = ({method, location, setLocation}) => {
    return (
        <div className={cn('container_form', styles.big__question)}>
            <p className={cn(styles.question, method === 'online' || method === '' ? 'invisible' : 'visible')}>Choose location</p>
            <RadioGroup method={method} location={location} setLocation={setLocation} />
        </div>
    );
};

export default RadioGroupQuestion;
