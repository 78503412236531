import React from 'react';
import styles from './Steps.module.css';
import CardStep, {CardStepProps} from "../../../components/mainPage/CardStep/CardStep";

const Steps = () => {
    const cards: Array<CardStepProps> = [
        {
            color: "green",
            hasButton: true,
            header: "1. Fill in a questionnaire",
            text: "Based on your answers, we will generate a list of psychologists with experience " +
                "in your area of concern",
        },
        {
            color: "blue_light",
            hasButton: false,
            header: "2. Choose a psychologist",
            text: "All psychologists are HPCSA accredited and have years of experience with therapy " +
                "professionally and personally",
        },
        {
            color: "blue_light",
            hasButton: false,
            header: "3. Make an appointment",
            text: "Booking an appointment doesn't require calls. Just select a psychologist " +
                "and you will be connected to them through the chat",
        },
    ];

    return (
        <div className={"container"}>
            <h2>
                Finding your psychologist is not easy.<br/><span className={styles.blue}>Balance</span> will help you!
            </h2>
            <div className={styles.cards}>
                {cards.map((card, index) => (
                    <CardStep
                        color={card.color}
                        hasButton={card.hasButton}
                        header={card.header}
                        text={card.text}
                        key={index}
                        id={index + 1}
                    />
                ))}
            </div>
        </div>
    );
};

export default Steps;
