import React, {ChangeEventHandler, FC} from 'react';
import styles from './Input.module.css';
import cn from "classnames";

interface InputProps {
    type: 'text' | 'number' | 'email' | 'tel';
    onChange: ChangeEventHandler<HTMLInputElement>;
    value: string;
    isValid: '' | 'valid' | 'invalid';
    autocomplete?: string;
}

const Input: FC<InputProps> = ({type, onChange, value, isValid, autocomplete}) => {


    return (
        <input
            type={type}
            className={cn(styles.input, styles[type], styles[isValid])}
            onChange={onChange}
            value={value}
            required
            autoComplete={autocomplete}
        />
    );
};

export default Input;
