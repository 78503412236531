import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import RadioQuestion from "../library/RadioQuestion/RadioQuestion";
import {validateRatio} from "../../validation/validation";

interface FormatProps {
    format: string;
    setFormat: Function;
    setIsFormatValid: Function;
    isBtnClicked: boolean;
}

const Format: FC<FormatProps> = ({format, setFormat, setIsFormatValid, isBtnClicked}) => {
    const [formatWarning, setFormatWarning] = useState<string>('');
    const [formatError, setFormatError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        format && checkFormat() || isBtnClicked && checkFormat();
    }, [format, isBtnClicked]);

    const handleChangeFormat = (event: ChangeEvent<HTMLInputElement>) => {
        setFormat(event.target.value);
    };

    const checkFormat = () => {
        const isValid = validateRatio(format);
        setIsFormatValid(isValid);
        if (isValid) {
            setFormatError('valid');
            setFormatWarning('');
        } else {
            setFormatError('invalid');
            setFormatWarning('This field should be filled in');
        }
    }

    return (
        <RadioQuestion
            question={"Session format"}
            radioButtonText={['Individual', 'Couple']}
            onChange={handleChangeFormat}
            warning={formatWarning}
            isError={formatError}
            radioFormType={'format'}
            currentValue={format}
        />
    );
};

export default Format;
