import React from 'react';
import Navbar from "../components/library/Navbar/Navbar";
import ButtonsBar from "../layout/general/ButtonsBar/ButtonsBar";
import Footer from "../components/library/Footer/Footer";
import PsychologistInfo from "../layout/specialists/PsychologistInfo/PsychologistInfo";

const Psychologist = () => {
    return (
        <div>
            <Navbar/>
            <ButtonsBar page={"appointment"} currentActiveButton={4}/>
            <PsychologistInfo/>
            <Footer/>
        </div>
    );
};

export default Psychologist;
