import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import styles from './PhoneContainer.module.css';
import cn from "classnames";
import WhatsAppIcon from "../../../static/images/whatsApp.svg";
import Input from "../../library/Input/Input";
import {validatePhone} from "../../../validation/validation";
import LocationIcon from "../../../static/images/location.svg";

interface PhoneContainerProps {
    btnClickAmount: number;
    phone: string;
    setPhone: Function;
    setIsPhoneValid: Function;
}

const PhoneContainer: FC<PhoneContainerProps> = ({btnClickAmount, phone, setPhone, setIsPhoneValid}) => {
    const [phoneWarning, setPhoneWarning] = useState<string>('');
    const [phoneError, setPhoneError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        (phone || btnClickAmount > 1) && checkPhone();
    }, [btnClickAmount, phone]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }

    const checkPhone = () => {
        const isValid = validatePhone(phone);
        setIsPhoneValid(isValid);
        if (isValid) {
            setPhoneError('valid');
            setPhoneWarning('');
        } else {
            setPhoneError('invalid');
            setPhoneWarning('Fill in your phone correctly');
        }
    }

    return (
        <div className={cn(styles.phone__container, btnClickAmount >= 1 && styles.visible)}>
            <p className={styles.phone__text}>
                We will create a chat for you with this psychologist in WhatsApp messenger
            </p>
            <div className={styles.phone__header}>
                <p className={styles.phone__header_text}>Your WhatsApp number </p>
                <input type={'image'} className={styles.phone__header_icon} src={`/${WhatsAppIcon}`} alt={'whats app'}/>
            </div>
            <Input type={'tel'} onChange={handleChange} value={phone} isValid={phoneError} autocomplete={'on'}/>
            { phoneWarning &&
                <p className={cn(styles.warning, phoneError === 'invalid' && styles.error)}>{phoneWarning}</p>
            }
        </div>
    );
};

export default PhoneContainer;
