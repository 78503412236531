import React, { FC } from 'react';
import styles from './Advantages.module.css';
import CardAdvantage, {CardAdvantageProps} from "../../../components/mainPage/CardAdvantage/CardAdvantage";

const Advantages: FC = () => {
    const cards: Array<CardAdvantageProps> = [
        {
            "icon": "heart",
            "header": "Professionals",
            "text": "We carefully select psychologists",
        },
        {
            "icon": "convenience",
            "header": "Convenience",
            "text": "Easily find the one you need",
        },
        {
            "icon": "chat",
            "header": "Support",
            "text": "Always available to help",
        }
    ];

    return (
        <div className="container">
            <div className={styles.cards__advantages}>
                {cards.map((card, index) => (
                    <CardAdvantage icon={card.icon} header={card.header} text={card.text} key={index}/>
                ))}
            </div>
        </div>
    );
};

export default Advantages;
