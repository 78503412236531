import React, {FC} from 'react';
import styles from './Quote.module.css';
import cn from "classnames";
import Button from "../../../components/library/Button/Button";
import {Link} from "react-router-dom";

interface QuoteProps {
    text: string;
    hasButton: boolean
}

const Quote: FC<QuoteProps> = ({text, hasButton}) => {
    return (
        <div className={styles.quote__container}>
            <div className="container">
                <h2 className={styles.quote}>{text}</h2>
                {hasButton && <Link to={'registration'}><Button appearance={'blue'}>Try a session</Button></Link>}
            </div>
        </div>
    );
};

export default Quote;
