import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {validateRatio} from "../../validation/validation";
import RadioQuestion from "../library/RadioQuestion/RadioQuestion";

interface ExperienceProps {
    experience: string;
    setExperience: Function;
    setIsExperienceValid: Function;
    isBtnClicked: boolean;
}

const Experience: FC<ExperienceProps> = ({experience, setExperience, setIsExperienceValid, isBtnClicked}) => {
    const [experienceWarning, setExperienceWarning] = useState<string>('');
    const [experienceError, setExperienceError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        (experience && checkExperience()) || (isBtnClicked && checkExperience());
    }, [experience, isBtnClicked]);

    const handleChangeExperience = (event: ChangeEvent<HTMLInputElement>) => {
        setExperience(event.target.value);
    };

    const checkExperience = () => {
        const isValid = validateRatio(experience);
        setIsExperienceValid(isValid);
        if (isValid) {
            setExperienceError('valid');
            setExperienceWarning('');
        } else {
            setExperienceError('invalid');
            setExperienceWarning('This field should be filled in');
        }
    }

    return (
        <RadioQuestion
            question={"Have you had therapy before?"}
            radioButtonText={['Yes', 'No']}
            onChange={handleChangeExperience}
            warning={experienceWarning}
            isError={experienceError}
            radioFormType={'experience'}
            currentValue={experience}
        />
    );
};

export default Experience;
