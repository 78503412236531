import React, {FC} from 'react';
import PersonPhoto1 from "../../../static/images/our-team-1.png";
import PersonPhoto2 from "../../../static/images/our-team-2.png";
import styles from "./Team.module.css";
import cn from "classnames";

const Team: FC = () => {
    return (
        <div className="container">
            <div className={styles.person}>
                <input
                    type="image"
                    className={cn(styles.person__photo, styles.margin_right)}
                    alt={"person"}
                    src={PersonPhoto1}
                />
                <div className={styles.person__quote}>
                    <h4 className={cn(styles.person__quote_text, styles.blue)}>
                        “I believe that psychotherapy lets people live happier lives and I want to help
                        those who feel the need for it”
                    </h4>
                    <h4 className={styles.person__quote_name}>Ruslan Dimitrov,<br/>Founder of Balance </h4>
                </div>
            </div>

            <div className={cn(styles.person, styles.position, styles.margin_bottom)}>
                <div className={cn(styles.person__quote, styles.margin_right)}>
                    <h4 className={cn(styles.person__quote_text, styles.green)}>
                        "Psychotherapy is the best way to understand yourself, your goals and capabilities.
                        It helps to focus on yourself"
                    </h4>
                    <h4 className={styles.person__quote_name}>Aida Chernyavskaya,<br/>Developer of Balance</h4>
                </div>
                <input type="image" className={styles.person__photo} alt={"person"} src={PersonPhoto2}/>
            </div>
        </div>
    );
};

export default Team;
