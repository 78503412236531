import React from 'react';
import Navbar from "../components/library/Navbar/Navbar";
import ButtonsBar from "../layout/general/ButtonsBar/ButtonsBar";
import SpecialistsList from "../layout/specialists/SpecialistsList/SpecialistsList";
import Footer from "../components/library/Footer/Footer";

const Specialists = () => {
    return (
        <div>
            <Navbar/>
            <ButtonsBar page={'specialists'} currentActiveButton={3}/>
            <SpecialistsList/>
            <Footer/>
        </div>
    );
};

export default Specialists;
