import React, { FC } from 'react';
import styles from './MainBlock.module.css';
import Button from "../../../components/library/Button/Button";
import OnlineCallPhotoCompressed from "../../../static/images/online-call-photo-compressed.png";
import {Link} from "react-router-dom";

const MainBlock: FC = () => {
    return (
        <div className="container">
            <div className={styles.main}>
                <div className={styles.main__text}>
                    <h2 className={styles.h2__align_left}>Find a psychologist that will truly understand you</h2>
                    <p className={styles.main__p}>Take a 1-minute test and we will match you with a psychologist just for you</p>
                    <Link to={'/registration'}>
                        <Button appearance={"blue_big"}>Find a psychologist</Button>
                    </Link>
                </div>
                <input type={"image"} className={styles.main__photo} src={OnlineCallPhotoCompressed} alt={"online call"}/>
            </div>
        </div>
    );
};

export default MainBlock;
