import axios from "axios";

export default class AppointmentService {

    static async sendData (phone: string, specialistId: number): Promise<boolean> {
        const token = localStorage.getItem('token');
        return await axios.post(`${process.env.REACT_APP_HOST}/api/v1/appointment/`, {
            phone: phone,
            specialist: specialistId,
        }, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
            .then(function () {
                return true;
            })
            .catch(function (error) {
                console.log(error.message);
                return false;
            })
    }
}
