import React, {useEffect, useState} from 'react';
import cn from "classnames";
import styles from './PersonalQuestions.module.css';
import Format from "../../../components/questionnaire/Format";
import Button from "../../../components/library/Button/Button";
import WorkWith from "../../../components/questionnaire/WorkWith";
import Method from "../../../components/questionnaire/Method";
import CheckboxQuestion from "../../../components/library/CheckboxQuestion/CheckboxQuestion";
import PersonalQuestionsService from "../../../API/PersonalQuestionsService";
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/library/Loader/Loader";

export interface IKidSymptom {
    id: number;
    symptom: string;
}
export interface IParentSymptom {
    id: number;
    symptom: string;
    kids: Array<IKidSymptom>
}

const formatToBoolean = (format: string) => {
    return format.toLowerCase() === 'personal';
};

const checkId = (symptomsId: Set<number>, savedId: Set<number>): number[] => {
    const existedId: number[] = [];
    savedId.forEach((el) => {
        if (symptomsId.has(el)) {
            existedId.push(el);
        }
    });
    return existedId;
};

const PersonalQuestions = () => {
    const [format, setFormat] = useState<string>('');
    const [isFormatValid, setIsFormatValid] = useState<boolean>(false);

    const [workWith, setWorkWith] = useState<string>('');
    const [isWorkWithValid, setIsWorkWithValid] = useState<boolean>(false);

    const [method, setMethod] = useState<string>('');
    const [isMethodValid, setIsMethodValid] = useState<boolean>(false);

    const [location, setLocation] = useState<number>(0);

    const [symptoms, setSymptoms] = useState<Set<number>>(new Set());
    const [isSymptomsValid, setIsSymptomsValid] = useState<boolean>(false);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isBtnClicked, setIsBtnClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [symptomsId, setSymptomsId] = useState<Set<number>>(new Set());
    const navigation = useNavigate();

    useEffect(() => {
        setIsFormValid(isFormatValid && isWorkWithValid && isMethodValid && isSymptomsValid)
    }, [isFormatValid, isWorkWithValid, isMethodValid, isSymptomsValid]);

    useEffect(() => {
        const savedData = localStorage.getItem('personalData');
        if (savedData) {
            const dataParsed = (JSON.parse(savedData));
            setWorkWith(dataParsed.work_with);
            setMethod(dataParsed.method);
            setFormat(dataParsed.format);
            setSymptoms(new Set(dataParsed.symptoms));
            setLocation(dataParsed.location);
        }
    },[]);

    async function fetchPersonalData () {
        const locationID: number | null = method === 'online' ? null : location;
        return await PersonalQuestionsService.sendData(formatToBoolean(format), checkId(symptomsId, symptoms), workWith, method, locationID);
    }

    const handleClick = () => {
        setIsBtnClicked(true);
        if (isFormValid) {
            setIsLoading(true);
            fetchPersonalData()
                .then(function (ok: boolean) {
                    if (!ok) {
                        navigation('/error');
                    } else {
                        const personalData = {
                            'format': format,
                            'work_with': workWith,
                            'method': method,
                            'symptoms': checkId(symptomsId, symptoms),
                            'location': location,
                        };
                        localStorage.setItem('personalData', JSON.stringify(personalData));
                        navigation('/specialists');
                    }
                });
        }
    };

    return (
        <div className={cn(styles.personal__questions, isLoading && styles.loading)}>
            {isLoading
                ? <Loader/>
                : <div>
                    <Format format={format} setFormat={setFormat} setIsFormatValid={setIsFormatValid} isBtnClicked={isBtnClicked}/>
                    <div className='container_form'>
                        <hr className={cn('margin_bottom_50px', 'hr')}/>
                    </div>
                    <CheckboxQuestion
                        symptoms={symptoms}
                        setSymptoms={setSymptoms}
                        setIsSymptomsValid={setIsSymptomsValid}
                        isBtnClicked={isBtnClicked}
                        setSymptomsId={setSymptomsId}
                    />
                    <div className='container_form'>
                        <hr className={cn('margin_bottom_50px', 'hr')}/>
                    </div>
                    <WorkWith
                        workWith={workWith}
                        setWorkWith={setWorkWith}
                        setIsWorkWithValid={setIsWorkWithValid}
                        isBtnClicked={isBtnClicked}
                    />
                    <div className='container_form'>
                        <hr className={cn('margin_bottom_50px', 'hr')}/>
                    </div>
                    <Method
                        method={method} setMethod={setMethod}
                        setIsMethodValid={setIsMethodValid} isBtnClicked={isBtnClicked}
                        location={location} setLocation={setLocation}
                    />
                    <Button appearance={"blue"} onClick={handleClick}>Submit</Button>
                </div>
            }
        </div>
    );
};

export default PersonalQuestions;
