import React, {useEffect, useState} from 'react';
import Button from "../../../components/library/Button/Button";
import styles from './generalQuestions.module.css';
import cn from "classnames";
import Name from "../../../components/registration/Name";
import Age from "../../../components/registration/Age";
import Experience from "../../../components/registration/Experience";
import Email from "../../../components/registration/Email";
import {useNavigate} from "react-router-dom";
import RegistrationService from "../../../API/RegistrationService";
import Loader from "../../../components/library/Loader/Loader";

export const MIN_AGE = 18;
export const MAX_AGE = 130;

const experienceToBoolean = (experience: string) => {
    return experience === 'Yes';
};

const GeneralQuestions = () => {
    const [name, setName] = useState<string>('');
    const [isNameValid, setIsNameValid] = useState<boolean>(false);

    const [age, setAge] = useState<string>('');
    const [isAgeValid, setIsAgeValid] = useState<boolean>(false);

    const [experience, setExperience] = useState<string>('');
    const [isExperienceValid, setIsExperienceValid] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isBtnClicked, setIsBtnClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigation = useNavigate();

    useEffect(() => {
        setIsFormValid(isNameValid && isAgeValid && isEmailValid && isExperienceValid);
    }, [isNameValid, isAgeValid, isEmailValid, isExperienceValid]);

    useEffect(() => {
        const savedData = localStorage.getItem('registrationData');
        if (savedData) {
            const dataParsed = (JSON.parse(savedData));
            setName(dataParsed.name);
            setAge(dataParsed.age);
            setExperience(dataParsed.experience);
            setEmail(dataParsed.email);
        }

    },[]);

    async function fetchRegistrationData (): Promise<boolean>{
        return await RegistrationService.sendRegistrationData(name, Number(age), experienceToBoolean(experience), email);
    }

    const handleClick = () => {
        setIsBtnClicked(true);
        if (isFormValid) {
            setIsLoading(true);
            fetchRegistrationData()
                .then(function (ok: boolean) {
                    if (!ok) {
                        navigation('/error');
                    } else {
                        const registrationData = {
                            'name': name,
                            'age': age,
                            'experience': experience,
                            'email': email,
                        };
                        localStorage.setItem('registrationData', JSON.stringify(registrationData));
                        navigation('/questions');
                    }
                });
        }

    };

    return (
        <div className={cn(styles.general_questions, isLoading && styles.loading)}>
            {isLoading
                ? <Loader/>
                : <div>
                    <Name name={name} setName={setName} isBtnClicked={isBtnClicked} setIsNameValid={setIsNameValid}/>
                    <Age age={age} setAge={setAge} isBtnClicked={isBtnClicked} setIsAgeValid={setIsAgeValid}/>
                    <Experience
                        experience={experience}
                        setExperience={setExperience}
                        setIsExperienceValid={setIsExperienceValid}
                        isBtnClicked={isBtnClicked}
                    />
                    <div className='container_form'>
                        <hr className={cn('margin_bottom_50px', 'hr')}/>
                    </div>
                    <Email email={email} setEmail={setEmail} isBtnClicked={isBtnClicked} setIsEmailValid={setIsEmailValid}/>
                    <Button appearance={"blue"} onClick={handleClick}>Next</Button>
                </div>
            }
        </div>
    );
};

export default GeneralQuestions;
