import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import FormQuestion from "../library/FormQuestion/FormQuestion";
import {validateEmail} from "../../validation/validation";

interface EmailProps {
    email: string;
    setEmail: Function;
    isBtnClicked: boolean;
    setIsEmailValid: Function;
}

const Email: FC<EmailProps> = ({email, setEmail, isBtnClicked, setIsEmailValid}) => {
    const [emailWarning, setEmailWarning] = useState<string>('');
    const [emailError, setEmailError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        (email && checkEmail()) || (emailError && checkEmail())  || (isBtnClicked && checkEmail());
    }, [email, isBtnClicked]);

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const checkEmail = () => {
        const isEmailValid = validateEmail(email);
        setIsEmailValid(isEmailValid);
        if (isEmailValid) {
            setEmailError('valid');
            setEmailWarning('');
        } else {
            setEmailError('invalid');
            setEmailWarning('Please enter a valid email address');
        }
    }

    return (
        <FormQuestion
            question={"Email"}
            type={'email'}
            value={email}
            onChange={handleChangeEmail}
            warning={emailWarning}
            isError={emailError}
            autocomplete={'email'}
        />
    );
};

export default Email;
