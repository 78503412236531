import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {routes} from "./router";

const AppRouter = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0,0)
    }, [pathname]);

    return (
        <Routes>
            {routes.map(route =>
                <Route path={route.path} element={route.element} key={route.path}/>
            )}
            <Route path="/*" element={<Navigate replace to="/"/>}/>
        </Routes>
    );
};

export default AppRouter;
