import React from 'react';
import Navbar from "../components/library/Navbar/Navbar";
import ButtonsBar from "../layout/general/ButtonsBar/ButtonsBar";
import PersonalQuestions from "../layout/questionnaire/PersonalQuestions/PersonalQuestions";
import Footer from "../components/library/Footer/Footer";

const Questions = () => {
    return (
        <div>
            <Navbar/>
            <ButtonsBar page={'questions'} currentActiveButton={2}/>
            <PersonalQuestions/>
            <Footer/>
        </div>
    );
};

export default Questions;
