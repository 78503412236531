import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import RadioQuestion from "../library/RadioQuestion/RadioQuestion";
import {validateRatio} from "../../validation/validation";
import RadioGroupQuestion from "../library/RadioGroupQuestion/RadioGroupQuestion";

interface MethodProps {
    method: string;
    setMethod: Function;
    setIsMethodValid: Function;
    isBtnClicked: boolean;
    location: number;
    setLocation: Function;
}

const Method: FC<MethodProps> = ({method, setMethod, setIsMethodValid, isBtnClicked, location, setLocation}) => {
    const [methodWarning, setMethodWarning] = useState<string>('');
    const [methodError, setMethodError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        method && checkMethod() || isBtnClicked && checkMethod();
    }, [method, isBtnClicked]);


    const handleChangeMethod = (event: ChangeEvent<HTMLInputElement>) => {
        setMethod(event.target.value);
    };

    const checkMethod = () => {
        const isValid = validateRatio(method);
        setIsMethodValid(isValid);
        if (isValid) {
            setMethodError('valid');
            setMethodWarning('');
        } else {
            setMethodError('invalid');
            setMethodWarning('This field should be filled in');
        }
    };

    return (
        <div>
            <RadioQuestion
                question={"Method of session"}
                radioButtonText={['Online', 'Offline', 'Both are suitable']}
                onChange={handleChangeMethod}
                warning={methodWarning}
                isError={methodError}
                radioFormType={'method'}
                elementsValues={['online', 'offline', 'both']}
                currentValue={method}
            />

            <RadioGroupQuestion method={method} location={location} setLocation={setLocation}/>
        </div>
    );
};

export default Method;
