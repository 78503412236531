export const validateString = (str: string, maxLength?: number): boolean => {
    if (maxLength) {
        return !!(str.replaceAll(' ', '').length) && str.length <= maxLength;
    } else {
        return !!(str.replaceAll(' ', '').length);
    }

};

export const validateNumber = (num: number, min?: number, max?: number): boolean => {
    if (min && max) {
        return num >= min && num < max;
    } else if (min && !max) {
        return num >= min;
    } else if (!min && max) {
        return num < max;
    } else {
        return !!num;
    }

};

export const validateEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
};

export const validateRatio = (ratio: string): boolean => {
    return !!(ratio);
};

export function validateSet<T> (set: Set<T>): boolean {
    return !!set.size;
}

export const validatePhone = (phone: string): boolean => {
    return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(phone) && phone.length >= 10;
}
