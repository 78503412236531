import React, {FC} from 'react';
import LocationIcon from "../../../static/images/location.svg";
import styles from "./Navbar.module.css";
import Logo from "../Logo/Logo";

const Navbar: FC = () => {
    return (
        <div className="container">
            <header className={styles.navbar}>
                <Logo/>
                <div className={styles.location}>
                    <input type="image" className={styles.location__icon} alt="location icon" src={`/${LocationIcon}`}/>
                    <h6 className={styles.location__text}>South Africa</h6>
                </div>
            </header>
        </div>
    );
};

export default Navbar;
