import React, {FC, useState} from 'react';
import styles from './SpecialistSidebar.module.css';
import Button from "../../../components/library/Button/Button";
import AppointmentService from "../../../API/AppointmentService";
import PhoneContainer from "../../../components/specialists/PhoneContainer/PhoneContainer";
import {useNavigate} from "react-router-dom";
import {getSpecialistId} from "../PsychologistInfo/PsychologistInfo";
import SpecialistPhoto from '../../../static/images/user.png';

interface SpecialistSidebarProps {
    photo?: string;
    setIsLoading: Function;
}

const SpecialistSidebar: FC<SpecialistSidebarProps> = ({photo, setIsLoading}) => {
    const [btnClickAmount, setBtnClickAmount] = useState<number>(0);
    const [phone, setPhone] = useState<string>('');
    const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
    const navigation = useNavigate();

    async function fetchPhoneNumber (): Promise<boolean> {
        return await AppointmentService.sendData(phone, getSpecialistId());
    }

    const handleClick = () => {
        setBtnClickAmount(btnClickAmount + 1);
        if (isPhoneValid) {
            setIsLoading(true);
            fetchPhoneNumber()
                .then(function (ok: boolean) {
                    if (!ok) {
                        navigation('/error');
                    } else {
                        window.gtag('event', 'conversion', { 'send_to': 'AW-11330126201/BfBkCKDWzswZEPmC0Joq'});
                        navigation('/success');
                    }
                })
        }
    }

    return (
        <div className={styles.photo__block}>
            <input className={styles.photo} type={"image"} alt={'Specialist'} src={photo ? photo : `/${SpecialistPhoto}`}/>
            <PhoneContainer
                btnClickAmount={btnClickAmount} phone={phone}
                setPhone={setPhone} setIsPhoneValid={setIsPhoneValid}
            />
            <Button appearance={'blue_wide'} onClick={handleClick}>Make an appointment</Button>
        </div>
    );
};

export default SpecialistSidebar;
