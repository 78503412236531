import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import FormQuestion from "../library/FormQuestion/FormQuestion";
import {validateString} from "../../validation/validation";

const MAX_NAME_LENGTH = 128;

interface NameProps {
    name: string;
    setName: Function;
    isBtnClicked: boolean;
    setIsNameValid: Function;
}

const Name: FC<NameProps> = ({name, setName, isBtnClicked, setIsNameValid}) => {
    const [nameWarning, setNameWarning] = useState<string>('Your name or nickname will be available to your specialist');
    const [nameError, setNameError] = useState<'' | 'valid' | 'invalid'>('');

    useEffect(() => {
        (name && checkName()) || (nameError && checkName()) || (isBtnClicked && checkName());
    }, [name, isBtnClicked]);

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const checkName = () => {
        const isValid = validateString(name, MAX_NAME_LENGTH);
        setIsNameValid(isValid);
        if (isValid) {
            setNameError('valid');
            setNameWarning('Your name or nickname will be available to your psychologist');
        } else {
            setNameError('invalid');
            setNameWarning('You should fill in your name');
        }
    }

    return (
        <FormQuestion
            question={"What is your name?"}
            type={'text'}
            value={name}
            onChange={handleChangeName}
            warning={nameWarning}
            isError={nameError}
            autocomplete={'given-name'}
        />
    );
};

export default Name;
