import React, {FC} from 'react';
import styles from './ButtonsBar.module.css';
import ButtonStep from "../../../components/library/ButtonStep/ButtonStep";
import cn from "classnames";
import {Link} from "react-router-dom";

interface ButtonsBarProps {
    page: 'registration' | 'questions' | 'specialists' | 'appointment';
    currentActiveButton: number;
}

const ButtonsBar: FC<ButtonsBarProps> = ({page, currentActiveButton}) => {
    const buttonsText = ['General questions', 'Personal questions', 'Select a specialist', 'Make an appointment'];
    const pages = ['registration', 'questions', 'specialists', 'appointment'];

    return (
        <div className={cn(styles.buttons__container, 'container')}>
            {buttonsText.map((text, index) => (
                index + 1 < currentActiveButton
                    ? <Link to={`/${pages[index]}`} key={index}>
                        <ButtonStep
                            text={text}
                            current={page === pages[index]}
                            key={index}
                            id={index + 1}
                            currentActiveButton={currentActiveButton}
                        />
                      </Link>
                    : <ButtonStep
                        text={text}
                        current={page === pages[index]}
                        key={index}
                        id={index + 1}
                        currentActiveButton={currentActiveButton}
                      />
            ))}
        </div>
    );
};

export default ButtonsBar;
